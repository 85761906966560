import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stripeConnectionDialog", "waitingDialog", "cancelDialog", "blankPaymentSourcePreferenceForm"];

  openDialog () {
    this.stripeConnectionDialogTarget?.show();
  }

  closeDialog () {
    this.stripeConnectionDialogTarget?.hide();
  }

  waitingDialogTargetConnected (elm) {
    this.blockOverlayClick(elm);
  }

  cancelDialogTargetConnected (elm) {
    this.blockOverlayClick(elm);
  }

  agreeContinue () {
    this.closeDialog();

    stripe.collectFinancialConnectionsAccounts({ clientSecret: this.stripeConnectionDialogTarget.dataset.sessionToken }).then((data) => { // eslint-disable-line no-undef
      if (data.financialConnectionsSession?.accounts?.length === 0) {
        this.cancelDialogTarget.show();
        location.reload();
      } else {
        this.waitingDialogTarget.show();
        this.blankPaymentSourcePreferenceFormTarget.submit();
      }
    });
  }

  blockOverlayClick (elm) {
    elm.addEventListener("sl-request-close", event => {
      if (event.detail.source === "overlay") {
        event.preventDefault();
      }
    });
  }
}
